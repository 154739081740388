
import client from "../../utils/api/HTTPClient";
import api from "../../utils/api/apilist";
import { toast } from "react-toastify";


export const LOADQRFRONTSIDEDATA = 'LOADQRFRONTSIDEDATA';
export const QRFRONTSIDEDATASUCCESS = 'QRFRONTSIDEDATASUCCESS';
export const QRFRONTSIDEDATAFAILED = 'QRFRONTSIDEDATAFAILED';


export const loadQrFrontSideDataActions = () => {
    return {
        type: LOADQRFRONTSIDEDATA
    }
}


export const fetchQrFrontSideDataSuccessActions = (successMsg, qrFrontSideData) => {
    return {
        type: QRFRONTSIDEDATASUCCESS,
        successMsg,
        qrFrontSideData
    }
}


export const fetchQrFrontSideDataFailedActions = (errorMsg) => {
    return {
        type: QRFRONTSIDEDATAFAILED,
        errorMsg,
    }
}


export const fetchQrFrontSideDataActions = (postData) => {

    return dispatch => {
        dispatch(loadQrFrontSideDataActions());

        client.put(api.fetchQrFronside, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    dispatch(fetchQrFrontSideDataSuccessActions(response.message, response.result.data));
                } else {
                    dispatch(fetchQrFrontSideDataFailedActions(response.message));

                }
            } else {
                dispatch(fetchQrFrontSideDataFailedActions("Internal error occured.Please contact support."));

            }
        });
    }

}


export const updateQrFrontSideDataActions = (postData) => {

    return dispatch => {
        dispatch(loadQrFrontSideDataActions());

        client.put(api.updateQrFronside, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    toast.success("Updated successfully!")
                    dispatch(fetchQrFrontSideDataSuccessActions(response.message, response.result.data));
                } else {
                    dispatch(fetchQrFrontSideDataFailedActions(response.message));

                }
            } else {
                dispatch(fetchQrFrontSideDataFailedActions("Internal error occured.Please contact support."));

            }
        });
    }

}