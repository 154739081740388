import * as actionTypes from "../actions/actionTypes/frontsideSideBooking";

const initialState = {
    reservationFormSettings: null,
    workingTimeData: null,
    merchant: null,
    paymentgatewayInfo: null,
    newBookingDetails: null,
    loading: null,
    errorMsg: null,
    successMsg: null,
    error: null,
    selectedBooking: null,
    paymentModes: null,
    allPaymentModes: null,
}


export const customerSideBooking = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MERCHANTFORMSETTINGS:
            return {
                ...state,
                reservationFormSettings: action.payload
            }

        case actionTypes.SET_NEWBOOKINGDETAILS_CUSTOMER:
            return {
                ...state,
                newBookingDetails: {
                    ...state.newBookingDetails,
                    ...action.payload
                }

            }

        case actionTypes.ADDNEWBOOKINGS_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.ADDNEWBOOKINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                newBookingDetails: action.booking,
                successMsg: action.successMsg
            }

        case actionTypes.ADDNEWBOOKINGS_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                errorMsg: action.errorMsg
            }

        case actionTypes.FETCH_PAYMENTGATEWAYINFO_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.FETCH_PAYMENTGATEWAYINFO_SUCCESS:
            localStorage.setItem("tokenRes", `Bearer ${action.paymentgatewayInfo.result.data.api_key}`);

            return {
                ...state,
                loading: false,
                error: false,
                paymentgatewayInfo: action.paymentgatewayInfo.result.data,
                successMsg: action.successMsg,
                paymentModes: action.paymentgatewayInfo?.paymentModes?.enable_payment__method,
                allPaymentModes: action.paymentgatewayInfo?.paymentModes
            }

        case actionTypes.FETCH_PAYMENTGATEWAYINFO_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                errorMsg: action.errorMsg
            }

        case actionTypes.FETCH_WORKINGTIME_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.FETCH_WORKINGTIME_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                workingTimeData: action.workingTimeData,
                successMsg: action.successMsg
            }

        case actionTypes.FETCH_WORKINGTIME_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                errorMsg: action.errorMsg
            }

        case actionTypes.FETCH_MERCHANTDETAILS_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.FETCH_MERCHANTDETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                successMsg: action.successMsg,
                error: false,
                merchant: action.merchant
            }

        case actionTypes.FETCH_MERCHANTDETAILS_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                errorMsg: action.errorMsg
            }

        case actionTypes.FETCH_SINGLEBOOKING_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.FETCH_SINGLEBOOKING_SUCCESS:
            return {
                ...state,
                loading: false,
                successMsg: action.successMsg,
                error: false,
                selectedBooking: action.booking
            }

        case actionTypes.FETCH_SINGLEBOOKING_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                errorMsg: action.errorMsg
            }

        default:
            return state;
    }

}

export default customerSideBooking;