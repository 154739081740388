import client from "../../utils/api/HTTPClient";
import api from "../../utils/api/apilist";

export const LOADSEATMAPTABLESDATA = 'LOADSEATMAPTABLESDATA';
export const SEATMAPTABLESDATASUCCESS = 'SEATMAPTABLESDATASUCCESS';
export const SEATMAPTABLESDATAFAILED = 'SEATMAPTABLESDATAFAILED';


export const loadSeatMapTablesDataActions = () => {
    return {
        type: LOADSEATMAPTABLESDATA
    }
}


export const fetchSeatMapTablesDataSuccessActions = (successMsg, seatMapTables) => {
    return {
        type: SEATMAPTABLESDATASUCCESS,
        successMsg,
        seatMapTables
    }
}


export const fetchSeatMapTablesDataFailedActions = (errorMsg) => {
    return {
        type: SEATMAPTABLESDATAFAILED,
        errorMsg,
    }
}


export const fetchSeatMapTablesDataActions = (postData) => {
    return dispatch => {
        dispatch(loadSeatMapTablesDataActions());
        // console.log(postData);

        client.post(api.fetchSeatMapTableData, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    // console.log(response.result.data);
                    dispatch(fetchSeatMapTablesDataSuccessActions(response.message, response.result.data));
                } else {
                    dispatch(fetchSeatMapTablesDataFailedActions(response.message));

                }
            } else {
                dispatch(fetchSeatMapTablesDataFailedActions("Internal error occured.Please contact support."));

            }
        });
    }

}


export const addSeatMapTablesDataActions = (postData) => {

    return dispatch => {
        dispatch(loadSeatMapTablesDataActions());
        // console.log(postData);

        if (postData.tableData.image || postData.tableData.image !== undefined) {
            client.post(api.addSeatMapTableData, postData, (error, response) => {
                if (!error) {
                    if (!response.error) {
                        // console.log(response.result.data);
                        dispatch(fetchSeatMapTablesDataSuccessActions(response.message, response.result.data));
                    } else {
                        dispatch(fetchSeatMapTablesDataFailedActions(response.message));

                    }
                } else {
                    dispatch(fetchSeatMapTablesDataFailedActions("Internal error occured.Please contact support."));

                }
            });
        }
    }

}



export const updateSeatMapTablesIndexDataActions = (postData) => {

    return dispatch => {
        dispatch(loadSeatMapTablesDataActions());
        client.post(api.updateSeatMapTableIndexData, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    // console.log(response.result.data);
                    dispatch(fetchSeatMapTablesDataSuccessActions(response.message, response.result.data));
                } else {
                    dispatch(fetchSeatMapTablesDataFailedActions(response.message));

                }
            } else {
                dispatch(fetchSeatMapTablesDataFailedActions("Internal error occured.Please contact support."));
            }
        });
    }
};


export const updateSeatMapTablesDataActions = (postData) => {

    return dispatch => {
        dispatch(loadSeatMapTablesDataActions());
        // console.log(postData);

        if (postData.tableData.image || postData.tableData.image !== undefined) {
            client.post(api.updateSeatMapTableData, postData, (error, response) => {
                if (!error) {
                    if (!response.error) {
                        // console.log(response.result.data);
                        dispatch(fetchSeatMapTablesDataSuccessActions(response.message, response.result.data));
                    } else {
                        dispatch(fetchSeatMapTablesDataFailedActions(response.message));

                    }
                } else {
                    dispatch(fetchSeatMapTablesDataFailedActions("Internal error occured.Please contact support."));

                }
            });
        }
    }

};



export const deleteSeatMapTablesDataActions = (postData) => {

    return dispatch => {
        dispatch(loadSeatMapTablesDataActions());
        // console.log(postData);

        client.post(api.deleteSeatMapTableData, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    // console.log(response.result.data);
                    dispatch(fetchSeatMapTablesDataSuccessActions(response.message, response.result.data));
                } else {
                    dispatch(fetchSeatMapTablesDataFailedActions(response.message));

                }
            } else {
                dispatch(fetchSeatMapTablesDataFailedActions("Internal error occured.Please contact support."));

            }
        });
    }

};