import { Loyalty } from "@material-ui/icons";

const api = {

	customer_login: 'api/auth/customer_login',
	customer_signup: 'api/auth/customer_signup',
	forgot_password_otp: 'api/auth/forgot_password_otp',
	forgot_password_verify_otp: 'api/auth/forgot_password_verify_otp',
	create_password: 'api/auth/create_password',
	creat_new_pin: 'api/auth/creat_new_pin',

	topupWallet: "api/v2/wallet/createTransaction",
	finalTopupWallet: "api/v2/wallet/topup_to_wallet",

	get_customer_cards: "api/customers/get_customer_cards",
	validate_customers: "api/validate_customers",
	validate_customers_browser: "api/validate_customers_browser",

	sendOpt: "api/customers/sendOpt",
	visitors_registration_otp: "api/customers/visitors_registration_otp",
	visitors_registration_otp_virify: "api/customers/visitors_registration_otp_virify",
	visitors_registration_details: "api/customers/visitors_registration_details",

	validateCutomer: "api/validate_customers",

	// sign up user
	merchant_signup_user: "api/v3/merchant_signup_user",
	verify_mobile: "api/v3/verify_mobile",
	request_otp: "api/v3/request_otp",
	user_details: "api/v3/user_details",
	create_business: "api/v3/create_business",
	user_login: "api/v3/merchant_user_login",

	userDetailsSave: "api/user/userDetailsSave",
	changePin: "api/user/changePin",
	changePassword: "api/user/changePassword",

	// store/merchant
	update_merchant_details: "api/v4/merchant/update_details",
	fetch_all_stores: "api/v4/merchant/fetch_all",
	exchangerate: "api/exchangerate/",
	create_merchant: "api/v4/merchant/create",

	sendEmailOtp: "api/v4/merchant/sendEmailOtp",
	storeEmailVerification: "api/v4/merchant/verifyEmail",
	merchantDetailsFromUniqueId: "api/v4/merchant/merchantDetailsFromUniqueId",

	// company
	update_company_details: "api/v4/company/update_details",
	create_company: "api/v4/company/create",
	uploadCompayProfile: "api/v4/company/uploadProfile",

	//Authentication
	merchant_register: "api/v3/merchant_signup_app",
	merchant_login: "api/auth/merchant_login",
	merchant_verifyOtp: "api/auth/merchant_mobile_verify",
	// merchant_verifyOtp: "api/auth/merchant_check_otp",
	merchant_newPassword: "api/auth/merchantChangePassword",
	merchant_setIpayPin: "api/auth/MerchantSetIpayPin",
	merchant_resendOtp: "api/auth/merchant_resend_otp",
	merchant_logOut: "api/auth/merchant_logout",
	mesrchant_setBussinessData: "api/v3/set_business_details",

	//Merchant
	merchant_details: "api/merchant_api/getmerchant_details",
	fetch_visitorRegistry: "api/customers/fetchvisitry",
	generate_qr_code: "businessapi/v1/merchant/generate_qr_code",
	updateProfile: "businessapi/v1/merchant/updateProfile",
	updateMercDetails: "api/merchant_api/update_profile",

	customer_update_profile: "api/customers/update_profile",
	customer_update_profile: "api/customers/update_profile",

	updateBusinessInfo: "api/merchant_api/update_business_details",
	generateSelfCheckinPoster: "businessapi/v1/merchant/generateSelfCheckinPoster",
	generatePaymentQrPoster: "businessapi/v1/merchant/generatePaymentQrPoster",
	bank_request_update: "businessapi/v1/merchant/bank_request_update",
	sendOtp: "businessapi/v1/merchant/sendOtp",
	sendDineinUrlRequest: "businessapi/v1/merchant/sendDineinUrlRequest",
	sendDeliveryPartnerEnquiry: "businessapi/v1/merchant/sendDeliveryPartnerEnquiry",
	sendSuspiciousMail: "businessapi/v1/merchant/sendSuspiciousMail",
	sendIncreaseSeatCapRequest: "businessapi/v1/merchant/sendIncreaseSeatCapRequest",
	updateVisitEntry: "businessapi/v1/merchant/updateVisitEntry",
	delete_select_visit: "businessapi/v1/merchant/deleteSelectedVisit",

	createhospsafePdf: "backendapi/pdf/createhospsafePdf",
	downloadHospoPdf: "backendapi/pdf/downloadHospoPdf",
	checkPassword: "businessapi/v1/merchant/checkPassword",

	// POS Category
	getPOSCategory: "api/pos/getcategories?id=",
	addPOSCategory: "api/pos/addcategories",
	editPOSCategory: "api/pos/editcategories?id=",
	deletePOSCategory: "api/pos/deletecategories?id=",

	//POS Sub Categories
	getPosSubCategory: "api/pos/getsubcategories?id=",
	addPosSubCategory: "api/pos/addsubcategories",
	editPosSubCategory: "api/pos/editsubcategories?id=",
	deletePosSubCategory: "api/pos/deletesubcategories?id=",

	//POS Items
	addPOSItems: "api/pos/items/add",
	fetchPOSItems: "api/pos/items/fetch",
	editPOSItems: "api/pos/items/edit",
	deletePOSItems: "api/pos/items/delete",
	addPOSIemOptions: "api/pos/item_options/add",
	fetchPOSIemOptions: "api/pos/item_options/fetch",
	editPOSItemOptions: "api/pos/item_options/edit?id=",
	deletePOSIemOptions: "api/pos/item_options/delete?id=",

	//Transactions
	wallet_payment: "transactions/wallet_payment",
	refund_request: "api/v2/wallet/add_refund_request",
	merchant_transactions: "api/transactions/history_new",
	wallet_details: "api/wallet/wallet_details",
	fetch_filtertranc: "businessapi/v1/transactions/fetch_filtertranc",

	topupFetch: "backendapi/paymentgateway/fetch_method",

	//quick bills
	fetch_bills: "api/v2/quickbills/fetch_bills",
	edit_bills: "api/v2/quickbills/update_bill",
	delete_bill: "api/v2/quickbills/delete_bill",

	//notifications
	fetch_notifications: "businessapi/v1/notifications/allNotifications",

	//contact_requests
	addContactReq: "businessapi/v1/contactrequest/addcontactrequest",

	get_working_time: "businessapi/reservation-settings/get_working_time",
	working_time_Update: "businessapi/reservation-settings/working_time_Update",

	//mail templates
	add_email_templates: "businessapi/emailtemplate/add_email_templates",
	get_email_templates: "businessapi/emailtemplate/get_email_templates",
	update_email_templates: "businessapi/emailtemplate/update_email_templates",
	fetchSingleTemplate: "businessapi/emailtemplate/fetchSingleTemplate",

	//reservation settings
	fetch_tableStructure: "businessapi/reservation-settings/fetch_tableStructure",
	updateRestaurantStructure: "businessapi/reservation-settings/updateRestaurantStructure",
	fetchReservFormSettings: "businessapi/reservation-settings/fetchReservFormSettings",
	updateReservFormSettings: "businessapi/reservation-settings/updateReservFormSettings",

	//reservations
	addbooking: "businessapi/bookings/addbooking",
	fetchbookings: "businessapi/bookings/fetchbookings",
	fetchSinglebooking: "businessapi/bookings/fetchSinglebooking",
	deletebooking: "businessapi/bookings/deletebooking",
	updatebooking: "businessapi/bookings/updatebooking",
	createStripeCheckoutSession: "businessapi/bookings/create_stripe_checkout_session",

	//Users & roles
	getAllRoles: "api/role/getallroles?id=",
	addRoles: "api/role/addrole",
	editRole: "api/role/editroles?id=",
	getAllSubUsers: "api/user/getsubusers?id=",
	AddSubUser: "api/user/adduser",
	editSubUser: "api/user/editsubuser?id=",
	deleteSubUser: "api/user/deletesubuser?id=",

	//Supplier
	addSupplier: "api/supplier/addsupplier",
	getSuppliers: "api/supplier/getsuppliers?id=",
	deleteSuppier: "api/supplier/deletesupplier?id=",
	editSupplier: "api/supplier/editsupplier?id=",

	//Customer
	addCustomer: "api/mcustomer/addcustomer",
	getCustomer: "api/mcustomer/getcustomer?id=",
	deleteCustomer: "api/mcustomer/deletecustomer?id=",
	editCustomer: "api/mcustomer/editcustomer?id=",

	//Taxes
	getTax: "api/tax/gettax?id=",
	addTax: "api/tax/addtax",
	editTax: "api/tax/edittax?id=",
	deleteTax: "api/tax/deletetax?id=",

	//Loyalty Card
	addLoyaltyCard: "api/loyaltycard/addloyaltycard",
	getLoyaltyCard: "api/loyaltycard/getloyaltycard?id=",
	editLoyaltyCard: "api/loyaltycard/editloyaltycard?id=",
	deleteLoyaltyCard: "api/loyaltycard/deleteloyaltycard?id=",
	shareCard: "api/loyaltycard/shareloyaltycard?id=",

	//mail
	send_mail: "businessapi/v1/merchant/send_mail",
	sendBusinessTemplateMail: "businessapi/emailtemplate/sendBusinessTemplateMail",

	//delivery partners
	sf_restaurantInfo: "http://dm.speedfood.com.au/hubapi/Restaurant/RestaurantInfo",
	sf_login: "http://dm.speedfood.com.au/hubapi/Authentication/login",

	//reservation
	customer_payment: "ipayapi/v1/customer/direct_payrequest",
	cancel_payment_request: "ipayapi/v1/auth/cancel_payment_request",
	fetchpaymentstatus: "ipayapi/v1/customer/fetchpaymentstatus",

	fetchSinglePaymentgateway: "ipayapi/v1/getSinglePaymentgateway",

	fetchSeatMapFloorsAndrooms: "businessapi/reservation-settings/fetch_seatMap_floorsAndrooms",
	updateSeatMapFloorsAndrooms: "businessapi/reservation-settings/update_seatMap_floorsAndrooms",
	deleteSeatMapFloorsAndrooms: "businessapi/reservation-settings/delete_seatMap_floorsAndrooms",

	fetchSeatMapTableData: "businessapi/reservation-settings/fetch_seatMap_tableData",
	addSeatMapTableData: "businessapi/reservation-settings/add_seatMap_tableData",
	updateSeatMapTableIndexData: "businessapi/reservation-settings/update_seatMap_tableData_index",
	updateSeatMapTableData: "businessapi/reservation-settings/update_seatMap_tableData",
	deleteSeatMapTableData: "businessapi/reservation-settings/delete_seatMap_tableData",

	fetchSeatMapComponentsData: "businessapi/reservation-settings/fetch_seatMap_ComponentsData",
	addSeatMapComponentsData: "businessapi/reservation-settings/add_seatMap_ComponentsData",
	updateSeatMapComponentsData: "businessapi/reservation-settings/update_seatMap_ComponentsData",
	deleteSeatMapComponentsData: "businessapi/reservation-settings/delete_seatMap_ComponentsData",

	fetchSeatMapTextComponents: "businessapi/reservation-settings/fetch_seatMap_TextComponents",
	addSeatMapTextComponents: "businessapi/reservation-settings/add_seatMap_TextComponents",
	updateSeatMapTextComponents: "businessapi/reservation-settings/update_seatMap_TextComponents",
	deleteSeatMapTextComponents: "businessapi/reservation-settings/delete_seatMap_TextComponents",

	// countries
	countries_list: "api/countries/fetch_app",

	downloadInvoice: "api/merchant_api/download_invoice",
	create_pass: "applewallet/create_pass",
	googleWallet: "api/minisite-events/addToGoogleWallet",
	checkPromoCode: "api/minisite-events/checkPromoCode",
	cashPayment: "api/merchant_api/cashPayment",
	freeTicket: "api/merchant_api/freeTicket",
	moneyrequestDetails: "api/merchant_api/moneyrequestDetails",
	update_ticket_customer_info: "api/merchant_api/update_ticket_customer_info",
	getCompanyDb: "api/merchant_api/getCompanyDb",
	requestMerchantImage: "api/merchant_api/requestMerchantImage",
	payment_status: "api/merchant_api/payment_status",
	payment_status_of_other_payment_mode: "api/merchant_api/payment_status_of_other_payment_mode",
	payment_session: "api/merchant_api/payment_session",
	create_payment_intent: "api/merchant_api/create_payment_intent",
	validatePayment: "api/merchant_api/validatePayment",
	payment_by_card: "api/merchant_api/payment_by_card",
	checkOnlineTransactionFees: "api/merchant_api/checkOnlineTransactionFees",
	card_to_wallet_money_transfer: "api/merchant_api/card_to_wallet_money_transfer",
	moneyrequest: "api/merchant_api/moneyrequest",
	enable_payment_method: "api/merchant_api/enable_payment_method",
    get_payment_methods: "api/countries/get_payment_methods",

	customer_send_otp: "api/auth/customer_resend_otp",
	verify_otp: "api/auth/customer_verify_otp",

	sentotp_unregistercustomer: "api/auth/unregister_customer_resend_otp",
	verify_otp_unregistercustomer: "api/auth/unregister_customer_verify_otp",
	

    sendMobileOtp: "api/v4/merchant/sendMobileOtp",
    storeMobileVerification: "api/v4/merchant/mobileVerification",

	login_verification_step: "api/auth/login_verification_step",

	fetchQrFronside: "businessapi/qr-frontside/fetch_qr_fronside",
	updateQrFronside: "businessapi/qr-frontside/update_qr_fronside",

	// fetch merchantData
	fetchMerchantData: "features/fetchMerchantData",

	// stripe connect
	create_connect_account: "api/merchant_api/create_connect_account",
	stripe_account: "api/merchant_api/stripe_account",
	upload_identity_document: "api/merchant_api/upload_document",

	get_payment_details: "api/merchant_api/get_payment_details",

	validate_merchant: "api/v4/merchant/validate",

	//invoice
	invoices_details: "api/v2/quickbills/invoices_details",
	transaction_records: "api/v2/quickbills/transaction_records",
	invoice_payment_session: "api/v2/quickbills/payment_session",
	invoice_payment_validate: "api/v2/quickbills/payment_validate",
	update_invoice_status: 'api/v2/quickbills/update_invoice_status',
	set_report: "api/v2/quickbills/set_report",

	// Forgot Password
	password_reset_otp: "api/v3/password_reset_otp",
	fp_verify_otp: "api/v3/fp_verify_otp",
	user_create_password: "api/v3/user_create_password",

	fetch_logo_and_business_type: 'whiteLabelMerchant/logo_and_business_type',

	//register
	merchant_signup_user: "api/v3/merchant_signup_user",
	complete_merchant_signup_2: "api/v3/complete_merchant_signup_2",
	merchant_user_resend_otp: "api/v3/merchant_user_resend_otp",
	verify_email_new_user: "api/v3/verify_email_new_user",

	// bizlink
    check_bizlink_availability: "api/merchant_api/check_bizlink_availability",

	// account checks for login
	google_signup: "api/v3/google_signup",
	apple_signup: "api/v3/apple_signup",
};

export default api;
