

import {
    LOADSEATMAPTABLESDATA,
    SEATMAPTABLESDATASUCCESS,
    SEATMAPTABLESDATAFAILED,
} from '../actions/seatMapTablesActions';
import _ from 'lodash';

const initState = {
    loading: null,
    error: null,
    successMsg: null,
    errorMsg: null,
    tables: [],
};

const seatMapTablesReducer = (state = initState, actions) => {


    switch (actions.type) {

        case LOADSEATMAPTABLESDATA:
            return {
                ...state,
                loading: true
            }

        case SEATMAPTABLESDATASUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                successMsg: actions.successMsg,
                tables: actions.seatMapTables,
            }


        case SEATMAPTABLESDATAFAILED:
            return {
                ...state,
                loading: true,
                error: true,
                errorMsg: actions.errorMsg
            }

        default:
            return state;

    }

}

export default seatMapTablesReducer;