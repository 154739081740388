

import {
    LOADQRFRONTSIDEDATA,
    QRFRONTSIDEDATASUCCESS,
    QRFRONTSIDEDATAFAILED,
} from '../actions/qrFrontSideActions';


const initState = {
    loading: null,
    error: null,
    successMsg: null,
    errorMsg: null,
    qrFrontSideData: []

};

const qrFrontSideReducer = (state = initState, actions) => {


    switch (actions.type) {

        case LOADQRFRONTSIDEDATA:
            return {
                ...state,
                loading: true
            }

        case QRFRONTSIDEDATASUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                successMsg: actions.successMsg,
                qrFrontSideData: actions.qrFrontSideData,
            }


        case QRFRONTSIDEDATAFAILED:
            return {
                ...state,
                loading: true,
                error: true,
                errorMsg: actions.errorMsg
            }

        default:
            return state;
    }

}

export default qrFrontSideReducer;