import React, { useEffect, Suspense, lazy } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import MainWrapper from "../MainWrapper";
// import NotFound404 from "../../DefaultPage/404/index";
// import Loadable from 'react-loadable';

// import IpayproPaymentsFormPage from "../../../pages/IpayproPayments/IpayproPaymentsFormPage";
// import IpayproPaymentsProcessPage from "../../../pages/IpayproPayments/IpayproPaymentsProcessPage";
// import IpayproPaymentsRedirectPage from "../../../pages/IpayproPayments/IpayproPaymentsRedirectPage";
// import IpayproSuccessPage from "../../../pages/IpayproPayments/IpayproSuccessPage";
// import IpayproFailedPage from "../../../pages/IpayproPayments/IpayproFailedPage";
// import AppLoyaltyCardsPage from "../../../pages/AppPages/appLoyaltyCardsPage/AppLoyaltyCardsPage";
// import FrontsideSideBookingRoutes from "../../../pages/frontsideSideBookingPages/frontsideSideBookingRoutes/FrontsideSideBookingRoutes";
// import IpayproAmountPage from "../../../pages/IpayproPayments/IpayproAmountPage";
// import IpayproDisplayInvoicePage from "../../../pages/IpayproPayments/IpayproDisplayInvoicePage";

// import BizLinkroutes from "./BizLinkroutes"

// const FrontsideSideBookingRoutesLoadable = Loadable({
// 	loader: FrontsideSideBookingRoutes,
// 	loading: () => <div>Loading</div>,
// });
const BizLinkroutes = lazy(() => import('./BizLinkroutes'));
const LoyaltyCards = lazy(() => import('./loyaltyCards'));
const MainRoutes = lazy(() => import('./mainRoutes'));
const Loading = () => <div>Loading...</div>;

const Router = (props) => {

	return (
		<MainWrapper>
			<main className="bg-light">
				<style>
					{
						`.bg-light {
							background-color: #f1f1f1!important;
						}`
					}
					{`.swal2-styled.swal2-confirm{
                                width : unset !important;
                            }`}
				</style>
				<Suspense fallback={Loading}>
					{window.location.hostname === "app.ipaypro.co" ? (
						<LoyaltyCards />
					) : window.location.hostname === "ipaypro.co" || window.location.hostname === "dm.ipaypro.co" ? (
						<BizLinkroutes />
					) : (
						<MainRoutes />
					)
					}
				</Suspense>

				{/* {window.location.hostname === "app.ipaypro.co" ? (
					<Switch>
						<Route path="/loyalty-cards/:id" component={AppLoyaltyCardsPage} />
						<Redirect from="*" to="/" />
					</Switch>
					) : window.location.hostname === "ipaypro.co" || window.location.hostname === "dm.ipaypro.co"  ||  window.location.hostname === "localhost" ? (
					<Switch>
						<Route path={`/biz/:merchant_id`} component={FrontsideSideBookingRoutesLoadable} />
						<Route path="/amount/:unique_id" component={IpayproAmountPage} />
						<Route path="/tipme/:unique_id" component={IpayproAmountPage} />
						<Redirect from="*" to="/" />
					</Switch>
				) : (
					<Switch>
						<Route path="/invoice/:invoice_no" component={IpayproDisplayInvoicePage} />
						<Route path="/tipme/:unique_id" component={IpayproAmountPage} />
						<Route path="/amount/:unique_id" component={IpayproAmountPage} />
						<Route path="/paynow/:payment_token" component={IpayproPaymentsFormPage} />
						<Route path="/:payment_token/processTransaction/:customer_id" component={IpayproPaymentsProcessPage} />
						<Route path="/redirect/:payment_token" component={IpayproPaymentsRedirectPage} />
						<Route path="/success/:payment_token" component={IpayproSuccessPage} />
						<Route path="/failed/:payment_token" component={IpayproFailedPage} />

						<Route path="/404" component={NotFound404} />
						<Redirect from="*" to="/" />

						
					</Switch>
				)} */}
			</main>
		</MainWrapper>
	);
};

export default withRouter(Router);
