
import { useState, useEffect } from 'react';
import client from './api/HTTPClient';
import api from './api/apilist'
const timeStamp = Date.now() // 0

export const getOidcStorageKey = () => {
  const authSettings = JSON.parse(localStorage.getItem('authSettings'));
  if (authSettings) {
    return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
  }
  return null;
};

export const getOidcInfo = () => {
  const key = getOidcStorageKey();
  if (key) {
    const oidc = JSON.parse(localStorage.getItem(key));
    return oidc;
  }
  return null;
};

export const getToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.id_token;
  }
  return null;
};

export const logoutSession = () => {
  return dispatch => {

    localStorage.removeItem('token');
    localStorage.removeItem('@customerId');
    localStorage.removeItem('@merchant_id');
    localStorage.removeItem('customer_type');

    dispatch({
      type: "UNSET_USER_DETAILS",
      result: ""
    })
  }

}

export const toggleStoreSelection = (value) => {
  return dispatch => {
    dispatch({
      type: "TOGGLE_MERCHANT_SELECTION",
      value: value
    })
  }
}
export const toggleIpayPinModal = (value, data=null) => {
  return dispatch => {
    dispatch({
      type: "TOGGLE_IPAY_PIN_MODAL",
      value: value,
      data: data,
    })
  }
}
export const togglemainloading = (value) => {
  return dispatch => {
    dispatch({
      type: "TOGGLE_MAIN_LOADING",
      value: value
    })
  }
}

export const displayCardBrand = (cardNum) => {
  if (cardNum) {
    // console.log(/^4[0-9]{6,}$/.test(cardNum), "visa")
    if (/^4[0-9]{6,}$/.test(cardNum)) {
      return "Visa"
    } else if (/^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/.test(cardNum)) {
      return "Mastercard"
    } else if (/^3[47][0-9]{13}$/.test(cardNum)) {
      return "American Express"
    } else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(cardNum)) {
      return "Diners Club"
    } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(cardNum)) {
      return "Discover"
    } else if (/^(?:2131|1800|35\d{3})\d{11}$/.test(cardNum)) {
      return "JCB"
    } else {
      return "Card"
    }
  }
  return " "
}

export const validateLoginSession = (closePinModal = false) => {


  return dispatch => {

    client.post(api.validateCutomer, {}, async function (error, result) {

      if (!error) {
        if (result && result.error == false) {

          const customer_type = localStorage.getItem("customer_type");

          localStorage.setItem('@customerId', result.result._id);
          dispatch({
            type: "SET_USER_DETAILS",
            result: result.result
          })
          if (closePinModal == true) {
            dispatch(toggleIpayPinModal(false))
          }

          if (!closePinModal) {
            if (customer_type == 2 && localStorage.getItem("@merchant_id")) {
              dispatch(validateStoreSession())
            } else {
              dispatch({
                type: "SET_ACCOUNT_ID",
                account_id: result.result._id
              })

            }
          }


        } else {
          if (result) {
            if (result.registered == false) {
              // return result;
            } else {
              // throw new Error(result.message)
            }
          }
          else {

          }

        }
      }
    });

  }

}

export const validateStoreSession = () => {

  const customer_id = localStorage.getItem("@customerId");
  const merchant_id = localStorage.getItem("@merchant_id");

  return dispatch => {

    client.post(api.validate_merchant, { merchant_id, admin_id: customer_id }, async function (error, result) {

      if (!error) {
        if (result && result.error == false) {

          dispatch({
            type: "SET_ACCOUNT_ID",
            account_id: merchant_id
          })

          dispatch({
            type: "SET_ACTIVE_STORE",
            result: result.result.merchant
          })

        } else {
          if (result) {
            if (result.registered == false) {
              // return result;
            } else {
              // throw new Error(result.message)
            }
          }
          else {

          }

        }
      }
    });

  }

}


export const getTimeZone = (company) => {

  try {

    if (company && company.timeZone) {
      return company.timeZone;
    } else {
      return "Australia/Sydney";
    }
  } catch {
    return "Australia/Sydney";
  }

}

export const renderImageWithTimestamp = (url) => {

  return url?.includes("dropbox") ? url?.replace('?', `?t=${timeStamp}&`) : url
}

export const useWindowSize = () => {

  const [dimension, setDimension] = useState({
    pageHeight: window.innerHeight,
    pageWidth: window.innerWidth
  });

  const updateDimensions = () => {
    setDimension({
      pageHeight: window.innerHeight,
      pageWidth: window.innerWidth
    })
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return dimension;

}

export const fileDownload = (data, filename, mime, bom) => {
  var blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
  var blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE workaround for "HTML7007: One or more blob URLs were
      // revoked by closing the blob for which they were created.
      // These URLs will no longer resolve as the data backing
      // the URL has been freed."
      window.navigator.msSaveBlob(blob, filename);
  } else {
      var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
      var tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
      }

      document.body.appendChild(tempLink);
      tempLink.click();

      // Fixes "webkit blob resource error 1"
      setTimeout(function() {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
      }, 200)
  }
}