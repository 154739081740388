const initialState = {
    loginUser: null,
    store: null,
    isLoggedIn: false,
    account_id: null,
    showMerchantSelectModal: false,
    showIpayPinModal: false,
    formdata: null,
    mainLoading: false,
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case "SET_USER_DETAILS":
            return {
                ...state,
                loginUser: action.result,
                isLoggedIn: true
            }
        
        case "SET_ACTIVE_STORE":
            return {
                ...state,
                store: action.result,
            }
        
        case "SET_ACCOUNT_ID":
            return {
                ...state,
                account_id: action.account_id,
            }
        
        case "UNSET_USER_DETAILS":
            return {
                ...state,
                loginUser: null,
                isLoggedIn: false,
                store : null
            }
        
        case "TOGGLE_MERCHANT_SELECTION":
            return {
                ...state,
                showMerchantSelectModal: action.value,
            }
        
        case "TOGGLE_IPAY_PIN_MODAL":
            return {
                ...state,
                showIpayPinModal: action.value,
                formdata: action.data || null,
            }
        case "TOGGLE_MAIN_LOADING":
            return {
                ...state,
                mainLoading: action.value,
            }

        default:
            return state;
        
    }
}

export default authReducer;