export const ADDNEWBOOKINGS_START = "ADDNEWBOOKINGS_START";
export const ADDNEWBOOKINGS_SUCCESS = "ADDNEWBOOKINGS_SUCCESS";
export const ADDNEWBOOKINGS_FAILED = "ADDNEWBOOKINGS_FAILED";

export const SET_NEWBOOKINGDETAILS_CUSTOMER = "SET_NEWBOOKINGDETAILS_CUSTOMER";

export const SET_MERCHANTFORMSETTINGS = "SET_MERCHANTFORMSETTINGS";

export const FETCH_PAYMENTGATEWAYINFO_START = "FETCH_PAYMENTGATEWAYINFO_START";
export const FETCH_PAYMENTGATEWAYINFO_SUCCESS = "FETCH_PAYMENTGATEWAYINFO_SUCCESS";
export const FETCH_PAYMENTGATEWAYINFO_FAILED = "FETCH_PAYMENTGATEWAYINFO_FAILED";

export const FETCH_WORKINGTIME_START = "FETCH_WORKINGTIME_START";
export const FETCH_WORKINGTIME_SUCCESS = "FETCH_WORKINGTIME_SUCCESS";
export const FETCH_WORKINGTIME_FAILED = "FETCH_WORKINGTIME_FAILED";

export const FETCH_MERCHANTDETAILS_START = "FETCH_MERCHANTDETAILS_START";
export const FETCH_MERCHANTDETAILS_SUCCESS = "FETCH_MERCHANTDETAILS_SUCCESS";
export const FETCH_MERCHANTDETAILS_FAILED = "FETCH_MERCHANTDETAILS_FAILED";

export const FETCH_SINGLEBOOKING_START = "FETCH_SINGLEBOOKING_START";
export const FETCH_SINGLEBOOKING_SUCCESS = "FETCH_SINGLEBOOKING_SUCCESS";
export const FETCH_SINGLEBOOKING_FAILED = "FETCH_SINGLEBOOKING_FAILED";

export const UPDATE_BOOKING_START = "UPDATE_BOOKING_START";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";
export const UPDATE_BOOKING_FAILED = "UPDATE_BOOKING_FAILED";